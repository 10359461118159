import { IconButton, useTheme } from "@mui/material";
import Box from "../common/Box";
import { Popover } from "../common/Popover";
import Typography from "../common/Typography";
import CloseIcon from "@mui/icons-material/Close";
import installationData from "../../mock-data/apiKeyInstallationSteps.json";
import { CopyBlock, dracula } from "react-code-blocks";
import { ReactComponent as CopyIcon } from "../../assets/img/copy.svg";
import TextButton from "../common/TextButton";
import { TOASTER_TYPES, Toaster } from "../../utils/toaster";

export const PackageInstallationInfo = ({ open, anchorEl, handleClose }) => {
  const theme = useTheme();
  const styles = {
    popover: {
      ".MuiPaper-root": {
        background: theme.palette.surface10.main,
        padding: theme.spacing(2.5),
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(2),
        maxHeight: "100%",
      },
    },
    popoverBody: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
    iconButton: {
      padding: 0,
    },
    key: {
      fontWeight: 500,
      flexShrink: 0,
    },
    keyValue: {
      display: "flex",
      gap: theme.spacing(2.5),
    },
    popoverHeader: {
      display: "flex",
      justifyContent: "space-between",
    },
    valueWithImage: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2.5),
      width: "100%",
    },
    codeBox: {
      background: "#202122",
      padding: theme.spacing(1),
    },
    instruction: {
      display: "flex",
      gap: theme.spacing(1),
    },
  };

  const copyCommand = (command) => {
    if (command) {
      navigator.clipboard.writeText(command);
      Toaster(TOASTER_TYPES.SUCCESS, "Pip command copied!!");
    }
  };

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      handleClose={handleClose}
      sx={styles.popover}
    >
      <Box sx={styles.popoverHeader}>
        <Typography variant="h6">Install Instructions</Typography>
        <IconButton onClick={handleClose} sx={styles.iconButton}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>
      <Box sx={styles.popoverBody}>
        {installationData?.steps?.map((item, index) => (
          <Box sx={styles.keyValue}>
            <Typography
              variant="caption"
              color={theme.palette.surface50.main}
              sx={styles.key}
            >
              Step {index + 1}:
            </Typography>

            <Box sx={styles.valueWithImage}>
              <Typography
                variant="caption"
                color={theme.palette.surface50.main}>
                {item?.step}
              </Typography>
              {item?.code ? (
                <Box className="codeBlock">
                  <CopyBlock
                    language={"python"}
                    text={item?.code || ""}
                    showLineNumbers={false}
                    theme={dracula}
                    codeBlock
                  />
                </Box>
              ) : null}
            </Box>
          </Box>
        ))}
      </Box>
    </Popover>
  );
};
